<template>
  <div class="tinymce">
    <Editor v-model="contentValue" :init="init" />
  </div>
</template>
<script>
import tinyMCE from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import '@/plugins/tinymce/paragraphspacing/index.js'
import '@/plugins/tinymce/paragraphspacingbefore/index.js'
import 'tinymce/icons/default/icons'
import 'tinymce/plugins/image'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/table'
import 'tinymce/plugins/media'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/lists'
import { CONFIG } from '@/utils/config.js'
import { getCookie } from '@/utils/auth'
export default {
  components: {
    Editor
  },
  props: {
    id: {
      type: String,
      default: function () {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    selector: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 400
    }
  },
  data() {
    var that = this
    return {
      tinymceId: that.id,

      init: {
        selector: `#${that.selector}`,
        language_url: '/tinymce/zh_CN.js', // 语言包的路径
        language: 'zh_CN', // 语言
        skin_url: '/tinymce/skins/ui/oxide', // skin路径，注意这个，很多网上教程都是skins/lightgray，但是发现其实并没有这个玩意儿
        width: '375px',
        height: that.height, // 编辑器高度
        branding: false, // 是否禁用“Powered by TinyMCE”
        menubar: 'true',
        plugins: ['image', 'media', 'preview', 'paste', 'table', 'fullscreen', 'lists', 'paragraphspacing', 'paragraphspacingbefore'],
        images_upload_url: CONFIG + this.path + '/rich/1/JpegQ75W750', // 上传图片配置
        paste_data_images: false,
        paste_preprocess: function (plugin, args) {
          args.content = args.content.replace(/(<img[\s\S]+?)src=(['"]http:[^'"]+)['"]/gi, "$1 src='null' alt='无法显示图片请删除‘")
        },
        file_picker_types: 'media',
        images_reuse_filename: true,
        media_live_embeds: true,
        table_resize_bars: false, // 禁止拖动拉伸表格
        table_default_attributes: { border: 0 },
        table_default_styles: {
          'box-sizing': 'border-box',
          width: '100%',
          'border-collapse': 'collapse'
        },
        content_css: '/tinymce/skins/content/default/content.css',
        content_style: 'img { max-width:100%;object-fit:contain;height:auto!important;vertical-align:top;}',
        toolbar: [
          ' bold italic underline strikethrough forecolor backcolor styleselect |fontsizeselect | bullist numlist | image media imagetools table | alignleft aligncenter alignright alignjustify paragraphspacing paragraphspacingbefore | preview fullscreen removeformat'
        ],
        fontsize_formats: '14px 16px 18px 24px 30px',
        style_formats: [
          {
            title: '首行缩进',
            block: 'p',
            styles: { 'text-indent': '2em' }
          }
        ],
        formats: {
          alignleft: { block: 'p', styles: { textAlign: 'left' } },
          aligncenter: { block: 'p', styles: { textAlign: 'center' } },
          alignright: { block: 'p', styles: { textAlign: 'right' } }
        },
        file_picker_callback: function (cb, value, meta) {
          // 当点击meidia图标上传时,判断meta.filetype == 'media'有必要，因为file_picker_callback是media(媒体)、image(图片)、file(文件)的共同入口
          if (meta.filetype === 'media') {
            // 创建一个隐藏的type=file的文件选择input
            const input = document.createElement('input')
            input.setAttribute('type', 'file')
            input.setAttribute('accept', 'video/mp4')
            input.onchange = function () {
              const file = this.files[0]
              // 视频大小不能超过50M
              if (file.size / 1024 / 1024 > 50) {
                that.$message1('上传视频的大小不能超过50M', 'warning')
                // tinyMCE.activeEditor.notificationManager.close()
                return
              }
              const notification = that.createProgressBar()

              // XHR请求
              var xhr, formData
              xhr = new XMLHttpRequest()
              xhr.open('POST', CONFIG + that.path + '/video/9/null')
              xhr.withCredentials = false
              xhr.setRequestHeader('auth', getCookie('Auth'))
              xhr.upload.onprogress = function (e) {
                // 进度(e.loaded / e.total * 100)
                const percent = ((e.loaded / e.total) * 100).toFixed(2)
                if (percent < 100) {
                  // tinymce.activeEditor.setProgressState(true);//是否显示loading状态：1（显示）0（隐藏）
                  notification.progressBar.value(percent)
                } else {
                  // tinymce.activeEditor.setProgressState(false);
                  notification.progressBar.value(percent)
                  // 关闭进度提示
                  tinyMCE.activeEditor.notificationManager.close()
                }
              }
              xhr.onerror = function () {
                console.log(xhr.status)
                // tinymce.activeEditor.setProgressState(false);
                tinyMCE.activeEditor.notificationManager.close()
              }
              xhr.onload = function () {
                var json
                // console.log(xhr.status)
                if (xhr.status < 200 || xhr.status >= 300) {
                  console.log('HTTP 错误: ' + xhr.status)
                  return
                }
                // tinyMCE.activeEditor.notificationManager.close()
                json = JSON.parse(xhr.responseText)
                // 假设接口返回JSON数据为{status: 0, msg: "上传成功", data: {location: "/localImgs/1546434503854.mp4"}}
                if (json.code === 0) {
                  // 接口返回的文件保存地址
                  const mediaLocation = json.location
                  // cb()回调函数，将mediaLocation显示在弹框输入框中
                  cb(mediaLocation, { title: file.name })
                } else {
                  console.log(json.msg)
                }
              }
              formData = new FormData()
              // 假设接口接收参数为file,值为选中的文件
              formData.append('file', file)
              // 正式使用将下面被注释的内容恢复
              xhr.send(formData)
            }
            // 触发点击
            input.click()
          }
        },
        images_upload_handler: function (blobInfo, success, failure) {
          var xhr, formData

          xhr = new XMLHttpRequest()
          xhr.withCredentials = false

          xhr.open('POST', CONFIG + that.path + '/rich/1/JpegQ75W750')
          xhr.setRequestHeader('auth', getCookie('Auth'))
          xhr.onload = function () {
            var json
            if (xhr.status !== 200) {
              failure('HTTP Error: ' + xhr.status)
              return
            }
            json = JSON.parse(xhr.responseText)
            if (!json || typeof json.location !== 'string') {
              failure('Invalid JSON: ' + xhr.responseText)
              return
            }
            success(json.location)
          }
          formData = new FormData()
          const file = blobInfo.blob()

          formData.append('file', file, file.name)
          xhr.send(formData)
        }
      },
      contentValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.contentValue = newValue
    },
    contentValue(newValue) {
      this.$emit('input', newValue)
    }
  },
  mounted() {
    tinyMCE.init({})
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    createProgressBar() {
      const notification = tinyMCE.activeEditor.notificationManager.open({
        text: '视频上传中,请耐心等耐',
        progressBar: true
      })

      const progressbarDialogNode = document.getElementsByClassName('tox-notifications-container')[0]
      // )
      // // 最重要的部分：必须使提示框的z-index值不小于68000
      progressbarDialogNode.style['z-index'] = 90000
      // // 以下部分调整提示框的样式

      progressbarDialogNode.style['border-radius'] = '5px'
      progressbarDialogNode.style['border'] = '1px solid #abdcff'
      progressbarDialogNode.querySelector('.tox-notification').style['background-color'] = '#f0faff'
      progressbarDialogNode.querySelector('.tox-notification').style['margin-top'] = '0'
      progressbarDialogNode.querySelector('.tox-notification__icon').style['display'] = 'none'
      progressbarDialogNode.querySelector('.tox-icon').style['display'] = 'none'
      return notification
    },
    setContent(value) {
      tinyMCE.get(this.tinymceId).setContent(value)
    },
    getContent() {
      tinyMCE.get(this.tinymceId).getContent()
    },
    destroyTinymce() {
      const tinymce = tinyMCE.get(this.tinymceId)
      // if (this.fullscreen) {
      //   tinymce.execCommand('mceFullScreen')
      // }
      if (tinymce) tinymce.destroy()
    }
  }
}
</script>
<style lang="scss" scoped>
.tinymce {
  max-width: 750px;
}
</style>
