/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2022-03-23 22:36:20
 * @LastEditors: 张志浩
 * @LastEditTime: 2022-07-05 14:09:29
 */
tinymce.PluginManager.add('paragraphspacing', function (editor, url) {
  const pluginName = '段前距'
  var global$1 = tinymce.util.Tools.resolve('tinymce.util.Tools')
  const paragraphspacing = editor.getParam('paragraphspacing', '0px 3px 5px 10px 15px 20px 30px 50px')
  editor.on('init', function () {
    editor.formatter.register({
      paragraphspacing: {
        // block: 'p',

        selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
        toggle: true,
        styles: { marginTop: '%value' },
        clear_child_styles: true
      }
    })
  })
  const doAct = function (value) {
    // editor.undoManager.transact(function () {
    //   editor.focus()
    //   editor.formatter.apply('paragraphspacing', { value: value })
    // })
    editor.formatter.apply('paragraphspacing', { value: value })
    editor.fire('change', {})
  }

  editor.ui.registry.getAll().icons.paragraphspacing ||
    editor.ui.registry.addIcon(
      'paragraphspacing',
      // '<svg t="1647919461329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="882" width="24" height="24"> <g><path d="M844.8 354.56H460.8a25.6 25.6 0 0 1 0-51.2h384a25.6 25.6 0 0 1 0 51.2zM682.496 505.088H460.8a25.6 25.6 0 0 1 0-51.2h220.672a25.6 25.6 0 0 1 0 51.2zM844.8 655.872H460.8a25.6 25.6 0 0 1 0-51.2h384a25.6 25.6 0 0 1 0 51.2zM682.496 806.4H460.8a25.6 25.6 0 0 1 0-51.2h220.672a25.6 25.6 0 0 1 0 51.2zM260.352 467.712a107.776 107.776 0 1 1 107.776-107.776 108.032 108.032 0 0 1-107.776 107.776z m0-164.352a56.576 56.576 0 1 0 56.576 56.576 56.576 56.576 0 0 0-56.576-56.576zM260.352 773.632A107.776 107.776 0 1 1 368.128 665.6a108.032 108.032 0 0 1-107.776 108.032z m0-164.352a56.576 56.576 0 1 0 56.576 56.32 56.832 56.832 0 0 0-56.576-56.32z" p-id="883"></path><path d="M260.352 303.36a25.6 25.6 0 0 1-25.6-25.6V166.4a25.6 25.6 0 0 1 51.2 0v111.36a25.6 25.6 0 0 1-25.6 25.6zM260.352 883.2a25.6 25.6 0 0 1-25.6-25.6v-111.36a25.6 25.6 0 0 1 51.2 0v111.36a25.6 25.6 0 0 1-25.6 25.6zM260.352 609.28a25.6 25.6 0 0 1-25.6-25.6v-141.568a25.6 25.6 0 0 1 51.2 0v141.568a25.6 25.6 0 0 1-25.6 25.6z" p-id="884"></path></g></svg>'
      '<svg class="icon"  viewBox="0 0 1097 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"  p-id="882" width="16" height="16"><path d="M399.725714 225.133714L547.766857 15.872l148.041143 209.188571H399.725714zM37.302857 473.161143v-109.714286h1021.44v109.714286H37.376z m-2.925714 264.045714v-109.714286h1027.510857v109.714286H34.450286z m2.194286 260.608v-109.714286h1022.317714v109.714286H36.571429z" /></svg>'
    )

  editor.ui.registry.addMenuButton('paragraphspacing', {
    icon: 'paragraphspacing',
    // text: '段前距',
    tooltip: pluginName,
    fetch: function (callback) {
      var dom = editor.dom
      var block = editor.selection.getNode()
      // console.log(editor.selection)
      // console.log(blocks)
      var lhv = 0
      // global$1.each(blocks, function (block) {
      //   console.log(block)

      // })
      if (lhv == 0) {
        lhv = dom.getStyle(block, 'margin-top') ? dom.getStyle(block, 'margin-top') : 0
      }
      let items = paragraphspacing.split(' ').map(function (item) {
        var text = item
        var value = item
        return {
          type: 'togglemenuitem',
          text: text,
          active: lhv == value ? true : false,
          onAction: function () {
            doAct(item)
          }
        }
      })
      callback(items)
    }
  })

  return {
    getMetadata: function () {
      return {
        name: pluginName,
        url: 'https://github.com/yog-zhang/tinymce-plugins.git'
      }
    }
  }
})
